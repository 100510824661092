/*jshint browser: true*/
/*global $, google, Enumerable, angular, toDate, rotator */
/*global getMapOptions, getMap, getDirectionsDisplay, getSearchBox, massTimesApp, placeMarker */
( function () {

massTimesApp.controller('MapController', ['$scope', '$rootScope', 'parishService', 'geoLocationFactory', 'userSettingsFactory', '$location', '$state', '$window', '$timeout', 'leafletData',
function MapController ($scope, $rootScope, parishService, geoLocationFactory, userSettingsFactory, $location, $state, $window, $timeout, leafletData, ) {
    var mc = this;
    mc.app = isApp != "Web";
    mc.location = $location;
    mc.$state = $state;
    mc.activeRs = activeRsCount;
    var widthIsFiltersCollapsed = 991;
    var widthIsNarrow = 768;
    mc.intersperseRCount = 0;
    mc.intersperseRMap = {};
    mc.languages = [];//['English', 'Spanish', 'Latin', 'Tagalog', 'Vietnamese', 'Polish', 'French'];
    mc.riteList = [];//['Latin', 'Armenian', 'Byzantine', 'Chaldean', 'Maronite', 'Syro-Malabar'];
    // mc.sortList = ['Time', 'Distance', 'Name (A-Z)', 'Most Reviewed', 'Last Updated'];
    mc.language = "Language";
    mc.riteFilter = "Rite";
    mc.timesString = "Times";
    mc.timesButtonText = "Times";
    mc.filterStartTimeInMinutes = 0;
    mc.filterEndTimeInMinutes = 1440;
    mc.collapseAllText = "Collapse";
    mc.DayOfWeekClicked = null;
    mc.ServiceTypeClicked = null;
    mc.loadExtraParishesText = "Load 30 more Parishes";
    let rawParishList = null;
    let unfilteredparishes = null;
    mc.parishPage = 1;
    mc.userSettings = {};
    let leafletMap = null;

    function convertNumberToTimeString(n) {
        if(n === 0 || n === 96){
            return "Midnight";
        }
        var minHours = Math.floor(n/4);
        var minMinutes = (n%4)*15;
        minMinutes = minMinutes == 0 ? "00" : minMinutes;
        return new Date(toDate(minHours + ":" + minMinutes, "h:m")).getFormattedTime(mc.userSettings.timeDisplay24);
    }

    function displayRs(){
        mc.activeRs = activeRsCount;
        rotator.display(true);
    }

    $(".slider").slider({
        range: true,
        min: 0,
        max: 96,
        values: [0, 96],
        slide: function( event, ui ) {
            setSliderTimeText(ui.values[0], ui.values[1]);
        }
    });

    function setSliderTimeText(from, to) {
        mc.filterStartTimeInMinutes = from * 15;
        mc.filterEndTimeInMinutes = to * 15;
        if (from === 0 && to === 96) {
            mc.timesString = 'Times';
            $('.slider-time').text('Anytime');
            return;
        }

        var fromTime = convertNumberToTimeString(from);
        var toTime = convertNumberToTimeString(to);

        if(fromTime === toTime){
            mc.timesString = fromTime;
        }
        else {
            mc.timesString = fromTime + " - " + toTime;
        }
        $('.slider-time').text(mc.timesString);
    }

    mc.setSliderToNow = function () {
        var hour = new Date().getHours();
        var minutes = new Date().getMinutes();
        // scale of 0 to 96, with each int as .25hr, example 1 is 12:15am, 95 is 11:45pm
        var from = Math.floor((hour * 60 + minutes) / 15);
        setSliderTimeText(from, 96);
        mc.changeTimeFilter();
    }

    //https://masstimes.org/map?lat=42.318&lng=-84.02&SearchQueryTerm=48118
    if(($location.search()["lat"] && $location.search()["lng"]) || $location.search()["SearchQueryTerm"]) { // set if we used search on Home, NOT if we come in from btn
        // special case for QR code 2024 https://masstimes.org/map?lat=39.761&lng=-86.163&SearchQueryTerm=Lucas%20Oil%20Stadium&sortDistance
        if($location.search()["lat"] === '39.761' && $location.search()["lng"] === '-86.163' && $location.search()["SearchQueryTerm"] === "Lucas Oil Stadium"){
            // clear the querystring on url:
            $location.search({});
            // go to home page:
            $location.path('/');
            sa_event("redirect_nec");
        }
        //we have a location to go to (and set the search string to show it) or we have something to search for
        mc.detectLocation = false;
        parishService.detectLocation = false;
        parishService.latitude = $location.search()["lat"];
        parishService.longitude = $location.search()["lng"];
        parishService.locationName = $location.search()["SearchQueryTerm"];
    }

    $rootScope.$on('updateLocation', function(event, data) {
        if(!data) data = {latitude: mc.latitude, longitude: mc.longitude, locationName: mc.searchResult};
        moveToCurrent(data);
    });
    $rootScope.$on('updateSettings', function(event, newSettings) {

        var settingForResultsDisplayChanged = false;
        if((mc.userSettings.distanceDisplayKm != newSettings.distanceDisplayKm) ||
            (mc.userSettings.timeDisplay24 != newSettings.timeDisplay24)) {
            settingForResultsDisplayChanged = true;
        }

        if(mc.userSettings.sortDistance != newSettings.sortDistance) {
            if(newSettings.sortDistance && mc.DayOfWeek != mc.Daylist[7]) {
                // user selected Distance and we're not viewing All, so switch to All
                mc.ChangeDayOfWeek(mc.Daylist[7]);
            } else if (!newSettings.sortDistance && mc.DayOfWeek == mc.Daylist[7]) {
                // user selected Time and we're viewing All, so switch to Time
                mc.ChangeDayOfWeek();
            }
        }

        if((mc.userSettings.setTimeToNow != newSettings.setTimeToNow)){
            newSettings.setTimeToNow ? mc.setSliderToNow() : mc.resetTimes();
        }

        mc.userSettings = angular.copy(newSettings);

        if(settingForResultsDisplayChanged) {
            moveToCurrent({latitude: mc.latitude, longitude: mc.longitude, locationName: mc.searchResult}, false);
            setSliderTimeText(mc.filterStartTimeInMinutes / 15, mc.filterEndTimeInMinutes / 15);
            mc.changeTimeFilter();
        }
    });

    $rootScope.$on('clearSearch', function(event, data) {
        $scope.$broadcast('angucomplete-alt:clearInput', 'map-place-search');
        var mapPlaceSearchValue = angular.element('#map-place-search_value');
        if(mapPlaceSearchValue && mapPlaceSearchValue.length){
            mapPlaceSearchValue = mapPlaceSearchValue[0];
            mapPlaceSearchValue.value = '';
            mapPlaceSearchValue.focus();
        }
    });

    $(window).on('orientationchange', function(event) {
        $timeout(function(){
            resizeList();
        }, 300);
    });

    $scope.$on('$locationChangeSuccess', function (event, toURL, fromURL)
    {
        if(toURL.indexOf('map') > -1 && fromURL.indexOf('map') === -1){ // if we're coming TO map:
            // mc.previousBeforeMap = mc.previous.get();
            if (parishService.detectLocation) {
                mc.searchResult = null;
                parishService.detectLocation = false;
                findMyLocation();
            } else if(parishService.locationName && parishService.locationName !== mc.searchResult) {
                window.document.title = "MassTimes - " + parishService.locationName;
                mc.searchResult = parishService.locationName;
                $scope.$broadcast('angucomplete-alt:changeInput', 'map-place-search', parishService.locationName);
                moveToCurrent({latitude: parishService.latitude, longitude: parishService.longitude});
            } else if (mc.latitude && mc.longitude) {
                updateURL({latitude: mc.latitude, longitude: mc.longitude});
            }
        }
    });

    mc.Daylist = [
        { id: 1, name: 'Sunday', alias: 'Weekend' },
        { id: 2, name: 'Monday', alias: 'Weekdays' },
        { id: 3, name: 'Tuesday', alias: 'Weekdays' },
        { id: 4, name: 'Wednesday', alias: 'Weekdays' },
        { id: 5, name: 'Thursday', alias: 'Weekdays' },
        { id: 6, name: 'Friday', alias: 'Weekdays' },
        { id: 7, name: 'Saturday', alias: 'Weekend' },
        { id: 8, name: 'All', alias: 'All' }
    ];
    const dayMapping = {
        Sunday: 1,
        Monday: 2,
        Tuesday: 3,
        Wednesday: 4,
        Thursday: 5,
        Friday: 6,
        Saturday: 7,
        All: 8
    };

    mc.AssemblyTypes = [
        { name: 'Mass', searchName: 'Mass' },
        { name: 'Confession', searchName: 'Confessions' },
        { name: 'Adoration', searchName: 'Adorations' },
        { name: 'All', searchName: 'All' }
    ];
    //add this if we want it back in the available selectable filter list:
    //   { name: 'Vigil for Holy Days', searchName: 'Vigil for Holy Days' }
    // { name: 'Holy Days', searchName: 'Holy Days' },
    // { name: 'Devotions', searchName: 'Devotions' },

    var deRegisterResizable = $scope.$on("angular-resizable.resizeEnd", function (event, args) { //resizeEnd resizing
        if (args.id === "mapwrap") {
            var height = angular.element(document.querySelectorAll("#mapwrap")).height();
            resizeList(height);
        }
    });
    var listElem = angular.element(document.querySelectorAll("#list"));
    function resizeList(height) {
        $scope.$applyAsync(function(){
        // var mt = 6;
        if (window.innerWidth <= widthIsNarrow){
            if(height) mt = height;
            else {
                mt = 236;
            }
        } else {
            mt = 0;
        }
        mc.mapHeight = mt;
        var cssChanges = {
            marginTop: mt + 'px'
        };
        listElem.css(cssChanges);

        //narrow #list { margin-top: 236px;}
        //short #list { margin-top: 6px;}
        var navHeight = document.getElementById('top') ? document.getElementById('top').offsetHeight : 0;
        var filterContainerHeight = document.getElementById('desktop-filter-container').offsetHeight;
        var mobileFilterContainerHeight = document.getElementsByClassName('mobile-filter-container-buttons')[0].offsetHeight;
        // var mapCanvasHeight = document.getElementById('mapwrap').offsetHeight;
        var windowHeight = window.innerHeight;
        var windowWidth = window.innerWidth;

        // desktop or tablet
        var scrollableListContainer = document.getElementById("scrollable-list-container");
        var desktopFilterContainerFilter = document.getElementById("desktop-filter-container-filter");
        var mobileFilterContainer = document.getElementById("filter-service-type");
        if(windowWidth <= widthIsNarrow) {
            //mobile
            scrollableListContainer.style.marginTop = '0px';
            scrollableListContainer.style.height = (windowHeight - navHeight - mc.mapHeight) + 'px';
            mobileFilterContainer.style.marginTop = (mobileFilterContainerHeight - 43) + 'px';
        } else {
            scrollableListContainer.style.marginTop = (filterContainerHeight + mobileFilterContainerHeight) + 'px';
            scrollableListContainer.style.height = (windowHeight - navHeight - filterContainerHeight - mobileFilterContainerHeight) + 'px';
            desktopFilterContainerFilter.style.marginTop = (filterContainerHeight + mobileFilterContainerHeight - 22) + 'px';
            mobileFilterContainer.style.marginTop = (mobileFilterContainerHeight - 43) + 'px';
        }
        });
        if(leafletMap && mc.latitude) {
            leafletMap.setView([mc.latitude, mc.longitude], 11);
            // leafletMap.panTo([mc.latitude, mc.longitude]);
            leafletMap.invalidateSize();
        }
    }

    var w = angular.element($window);

    w.on('resize', function() {
        resizeList();
    });

    mc.resizeList = function() {
        resizeList();
    };

    // init, default to Today and Mass:
    mc.DayOfWeek = mc.Daylist[new Date().getDay()];
    mc.ServiceType = mc.AssemblyTypes[0];

    mc.detailsShowingParishId = { id: 0 };

    if (!mc.latitude) {
        if (!parishService.detectLocation && parishService.latitude) {
            mc.latitude = parishService.latitude;
        }
    } else mc.latitude = 0;
    if (!mc.longitude) {
        if (!parishService.detectLocation && parishService.longitude) {
            mc.longitude = parishService.longitude;
        }
    } else mc.longitude = 0;

    mc.parishService = parishService;
    var servTimes = [];
    mc.servTimes = servTimes;
    mc.filteredServTimes = mc.servTimes;
    mc.filteredTimes = [];

    //setup ngAutocomplete directive
    mc.searchOptions = {};
    mc.searchOptions.watchEnter = true;
    mc.searchDetails = '';
    $scope.$watch('mc.searchDetails', function(newValue, oldValue) {
        if(newValue) {
            geoLocationFactory.geoCodeResult(newValue).then(function (found){
                var latitude = found.location.y;
                var longitude = found.location.x;
                mc.searchResult = found.address;
                moveToCurrent({latitude: latitude, longitude: longitude});
                setSearchFormText();
            }).catch(function (err){});
        }
    });

    function setSearchFormText(){
        var navbarSearch = angular.element('#map-place-search-navbar_value');
        if(navbarSearch.length) navbarSearch[0].value = mc.searchResult ? mc.searchResult : '';
        var mapSearch = angular.element('#map-place-search_value');
        if(mapSearch.length) mapSearch[0].value = mc.searchResult ? mc.searchResult : '';
    }

    userSettingsFactory.loadSettings().then(function (userSettings) {
        mc.userSettings = userSettings;
        if(userSettings.sortDistance || mc.location.search()["sortDistance"]) mc.ChangeDayOfWeek(mc.Daylist[7], true);
        //if we came from search on home page, do not sense location
        if (parishService.detectLocation) {
            parishService.detectLocation = false;
            findMyLocation();
        }
        else if (mc.latitude && mc.longitude) {
            mc.searchResult = parishService.locationName;
            moveToCurrent({latitude: mc.latitude, longitude: mc.longitude});
            setSearchFormText();
        }
        else if (!mc.searchDetails) {
            if (!parishService.detectLocation && parishService.locationName) {
                // this will trigger watch on mc.searchDetails
                mc.searchDetails = {description: {text: parishService.locationName}};
            }
        }
        if(userSettings.setTimeToNow) mc.setSliderToNow();
    }).catch(function (err) {
        // err
    });

    mc.clearSearch = function(){
        $scope.$broadcast('angucomplete-alt:clearInput', 'map-place-search');
        $rootScope.$emit('clearSearch');
    };

    mc.ChangeDayOfWeek = function (newValue, doNotChangeRs) {
        if(!doNotChangeRs) resetDisplayR();
        if(!newValue){
            mc.DayOfWeek = mc.DayOfWeekClicked ? mc.DayOfWeekClicked : mc.Daylist[new Date().getDay()];
            mc.ServiceType = mc.ServiceTypeClicked ? mc.ServiceTypeClicked : mc.AssemblyTypes[0];
            mc.location.search("sortDistance", null).replace();
            mc.collapseAllText = "Collapse";
        } else {
            if(newValue && newValue.alias !== 'All') {
                // day of week clicked
                mc.DayOfWeekClicked = newValue;
                mc.location.search("sortDistance", null).replace();
                mc.collapseAllText = "Collapse";
            }
            if(newValue.alias !== 'All' && mc.DayOfWeek.alias === 'All' && mc.ServiceType.searchName === 'All'){
                // mc.Daylist[0-6] is Sunday-Saturday
                mc.ServiceType = mc.ServiceTypeClicked ? mc.ServiceTypeClicked : mc.AssemblyTypes[0];
                // userSettingsFactory.changeSetting('sortDistance', false);
            } else if(newValue.alias === 'All') {
                // mc.Daylist[7] is All
                mc.ServiceType = mc.AssemblyTypes[mc.AssemblyTypes.length - 1];
                mc.location.search("sortDistance", true).replace();
                // userSettingsFactory.changeSetting('sortDistance', true);
            }
            if (mc.DayOfWeek !== newValue) {
                mc.DayOfWeek = newValue;
            }
        }
        applyFilter(true, doNotChangeRs);
    };
    mc.ChangeServiceType = function (newValue) {
        resetDisplayR();
        if (newValue.searchName !== 'All') mc.ServiceTypeClicked = newValue;
        //'All', 'Mass', 'Confession', 'Holy Days', 'Devotions', 'Adoration'
        if (mc.ServiceType !== newValue) {
            if(newValue.searchName !== 'All' && mc.ServiceType.searchName === 'All' && mc.DayOfWeek.alias === 'All'){
                mc.DayOfWeek = mc.DayOfWeekClicked ? mc.DayOfWeekClicked : mc.Daylist[new Date().getDay()];
                mc.collapseAllText = "Collapse";
                mc.location.search("sortDistance", null).replace();
            }
            if ((newValue.searchName === 'All')) {
                // if service type 0-all then force day of week to 0-Sunday
                mc.DayOfWeek = mc.Daylist[mc.Daylist.length - 1];
                mc.location.search("sortDistance", true).replace();
            }
            mc.ServiceType = newValue;
            applyFilter();
        }
    };

    mc.ClearFilter = function () {
        $timeout(function(){
            filterReset();
            mc.resetTimes();
        });
    };

    mc.ClearLanguageRiteTimeFilters = function() {
        $timeout(function() {
            mc.language = "Language";
            mc.riteFilter = "Rite";
            mc.timesString = "Anytime";
            mc.timesButtonText = "Times";
            mc.resetTimes();
        });
    };

    mc.resetTimes = function () {
        $(".slider").slider({
            values: [ 0, 96 ]
        });
        mc.timesButtonText = "Times";
        mc.timesString = "Anytime";
        mc.filterStartTimeInMinutes = 0;
        mc.filterEndTimeInMinutes = 1440;
        $('.slider-time').text(mc.timesString);
        applyFilter();
    };

    mc.changeTimeFilter = function () {
        $timeout(function() {
            mc.timesButtonText = mc.timesString === "Anytime" ? "Times" : mc.timesString;
            var startTime = mc.filterStartTimeInMinutes === 0 ? 0 : mc.filterStartTimeInMinutes / 15;
            var endTime = mc.filterEndTimeInMinutes === 0 ? 0 : mc.filterEndTimeInMinutes / 15;
            $(".slider").slider({
                values: [startTime, endTime]
            });
            applyFilter();
        });
    };

    mc.getFilterCountString = function (){
        var i = 0;
        i += mc.timesButtonText === 'Times' ? 0 : 1;
        i += mc.language === 'Language' ? 0 : 1;
        i += mc.riteFilter === 'Rite' ? 0 : 1;
        return i;
    };

    mc.toggleHideExpandParishDetails = function(){
        var element = $(".rotate");
        if(mc.collapseAllText === "Collapse") {
            element.each(function(i, e){
                if(e.classList.contains("expand-chevron")) {
                    e.click();
                }
            });
            mc.collapseAllText = "Expand";
        }
        else{
            element.each(function(i, e){
                if(!e.classList.contains("expand-chevron")) {
                    e.click();
                }
            });
            mc.collapseAllText = "Collapse";
        }

    };
    function hideExpandParishDetails(){
        var element = $(".rotate");
        if(mc.collapseAllText === "Collapse") {
            element.each(function(i, e){
                if(!e.classList.contains("expand-chevron")) {
                    e.click();
                }
            });
        }
        else{
            element.each(function(i, e){
                if(e.classList.contains("expand-chevron")) {
                    e.click();
                }
            });
        }

    };

    mc.hideExpandedMobile = function(closeId) {
        $(closeId).collapse('hide');
    };

    mc.hideExpandedDesktop = function(leaveOpenId) {
        if(leaveOpenId !== 'multiCollapseLanguage'){
            $('#multiCollapseLanguage').collapse('hide');
        }
        if(leaveOpenId !== 'multiCollapseRite'){
            $('#multiCollapseRite').collapse('hide');
        }
        if(leaveOpenId !== 'multiCollapseTimes'){
            $('#multiCollapseTimes').collapse('hide');
        }
        if(leaveOpenId !== 'multiCollapseDistance'){
            $('#multiCollapseDistance').collapse('hide');
        }
        $timeout(function(){
            resizeList();
        });
    };

    mc.hideAllExpanded = function () {
        $('#multiCollapseLanguage').collapse('hide');
        $('#multiCollapseRite').collapse('hide');
        $('#multiCollapseTimes').collapse('hide');
        $('#multiCollapseDistance').collapse('hide');

        $('#multi-collapse-day').collapse('hide');
        $('#multi-collapse-service').collapse('hide');
        $('#multi-collapse-filter').collapse('hide');

    };

    mc.displayR = function(index, type){
        if(mc.intersperseRMap[index] !== undefined){
            return mc.intersperseRMap[index];
        }

        mc.intersperseRCount++;
        mc.intersperseRMap[index] = mc.intersperseRCount == 1 || (mc.intersperseRCount % 3 === 0);
        return mc.intersperseRMap[index];
    };

    function resetDisplayR(){
        mc.intersperseRCount = 0;
        mc.intersperseRMap = {};
    };

    function showHideParishDetails(selectedParishId, isFromMapClick)
    {
        if (mc.detailsShowingParishId.id === selectedParishId) {
            mc.detailsShowingParishId.id = 0;
        } else {
            mc.detailsShowingParishId.id = selectedParishId;
            if(!isFromMapClick) openInfoWindow(selectedParishId);
            else highlightParish(selectedParishId);
        }

        //add delay and then to slide this one into view (delay needed bc height needs calc after panel is hidden)
        setTimeout(function ()
        {
            if(isFromMapClick)
                scrollParishToTop(selectedParishId);
            else
                scrollIntoView($('#' + selectedParishId), $("#list"));
        }, 50);
    };

    mc.ShowInfoWindow = function (e, selectedParishId) {
        e.preventDefault();
        openInfoWindow(selectedParishId);
    };

    mc.ScrollToTopOfList = function () {
        scrollToTop($('#top-of-list'), $('#list-scrollable'));
    };

    mc.changeLanguage = function (language) {
        mc.language = language;
        applyFilter();
    };

    mc.changeRite = function (rite) {
        mc.riteFilter = rite;
        applyFilter();
    };

    mc.parishesWithoutTimesText = function() {
        var string = '';
        string += mc.DayOfWeek.name === 'All' ? '' : mc.DayOfWeek.name;
        string += mc.language === 'Language' ? '' : ' ' + mc.language;
        string += mc.riteFilter === 'Rite' ? '' : ' ' + mc.riteFilter;
        string += mc.ServiceType.name === 'All' ? '' : ' ' + mc.ServiceType.name;
        if(mc.timesButtonText !== 'Times' && string.length > 0){
            string += ', ';
        }
        string += mc.timesButtonText === 'Times' ? '' : mc.timesButtonText;
        return string;
    };

    mc.address = function (parish) {
        return (
            isApp == "iOSApp" ?
                "https://maps.apple.com/?q=" + encodeURIComponent(parish.name) + "&ll=" +  parish.latitude + "," + parish.longitude + "&address="
                : (isApp != "Web" ? "https://maps.google.com/maps?q=" : "https://www.google.com/maps/search/?api=1&query=") +
                    encodeURIComponent(parish.name + ", ")
            ) +
            encodeURIComponent(
                parish.church_address_street_address + ", " + parish.church_address_city_name + ", " +
                parish.church_address_providence_name + " " + parish.church_address_postal_code);
    }

    mc.canPrint = function () {
        return (typeof window.webkit == "object") || (typeof cordova == "object" && typeof cordova.plugins == "object" && typeof cordova.plugins.printer == "object");
    }
    mc.printPage = function () {
        if(typeof window.webkit == "object") window.webkit.messageHandlers.print.postMessage('print');
        else if(typeof cordova == "object" && typeof cordova.plugins == "object" && typeof cordova.plugins.printer == "object")
            cordova.plugins.printer.print();
        else window.print();
    }
    mc.loadExtraParishes = function() {
        mc.parishPage++;
        mc.parishService.getParishes(mc.latitude, mc.longitude, mc.parishPage)
            .then(function (data) {

                if (!data.data.length) {
                    mc.loadExtraParishesText = "All locations showing within maximum 100 miles"
                } else {
                    rawParishList = rawParishList.concat(data.data);
                    unfilteredparishes = cleanUnfilteredArray(angular.copy(rawParishList));

                    mc.parishes = unfilteredparishes;

                    setCount();

                    applyFilter(false);
                }

                resizeList();
                doWorker();
                if(mc.ServiceType.searchName == 'All'){
                    $timeout(function(){
                        hideExpandParishDetails();
                    }, 500);
                }
            })
            .catch(function (err) {
                resizeList();
            });
    };
    let markers = [];
    mc.layers = {
        baselayers: {
            osm: {
                name: 'Street Map',
                url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                type: 'xyz',
                layerOptions: {
                    subdomains: ['a', 'b', 'c'],
                }
            },
            imagery: {
                name: "Satellite",
                url: 'https://{s}.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                type: "xyz",
                layerOptions: {
                    subdomains: ['server', 'services'],
                }
            }
        }
    };
    const maxAttrWidth = 640;
    L.Control.TruncatedAttribution = L.Control.extend({
        options: {
            position: 'bottomleft',
        },
        attribs: {
            Prefix: '<a href="https://leafletjs.com/" target="_blank" rel="noopener">Leaflet</a> | ',
            Satellite: 'Satellite powered by <a href="https://www.esri.com" target="_blank" rel="noopener">Esri</a>, Maxar, Earthstar Geographics, and the GIS User Community',
            Street: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noopener">OpenStreetMap</a>'
        },
        onAdd: function(map) {
            const container = L.DomUtil.create('div', 'leaflet-control-attribution');
            container.style.minWidth = '170px';
            container.style.cursor = 'default';
            container.innerHTML = this.attribs.Prefix + this.attribs.Street;
            return container;
        },
        setAttrib: function(name) {
            const container = this._container;
            if (name === 'Satellite') {
                container.innerHTML = this.attribs.Prefix + this.attribs.Satellite;
                if (window.innerWidth <= maxAttrWidth) {
                    L.DomEvent.on(container, 'click', this._expandAttribution, this);
                    container.style.cursor = 'pointer';
                    this._truncateAttribution();
                } else {
                    this._expandAttribution(null, true);
                }
            } else {
                container.innerHTML = this.attribs.Prefix + this.attribs.Street;
                this._expandAttribution(null, true);
                L.DomEvent.off(container, 'click');
                container.style.cursor = 'default';
            }
        },
        _truncateAttribution: function() {
            const container = this._container;
            if (container.offsetWidth <= maxAttrWidth) {
                container.style.maxWidth = maxAttrWidth-440 + 'px';
                container.style.whiteSpace = 'nowrap';
                container.style.overflow = 'hidden';
                container.style.textOverflow = 'ellipsis';
            }
        },
        _expandAttribution: function(event, force = false) {
            const container = this._container;
            if (force || container.offsetWidth == maxAttrWidth-440) {
                container.style.maxWidth = 'none';
                container.style.whiteSpace = 'normal';
                container.style.overflow = 'visible';
                container.style.textOverflow = 'clip';
            } else {
                this._truncateAttribution();
            }
        }
    });
    L.Control.CustomAttribution = L.Control.extend({
        options: {
            position: 'bottomright'
        },
        onAdd: function(map) {
            const container = L.DomUtil.create('div', 'leaflet-control-attribution');
            container.style.minWidth = '170px';
            container.style.cursor = 'default';
            container.innerHTML = 'Church data &copy; Mass Times Trust';
            return container;
        }
    });
    leafletData.getMap().then(function(map) {
        // map is leaflet map object
        leafletMap = map;

        map.attributionControl.setPrefix(false);
        new L.Control.CustomAttribution().addTo(map);
        let attribContainer = new L.Control.TruncatedAttribution().addTo(map);
        map.on('baselayerchange', function(e) {
            attribContainer.setAttrib(e.name);
        });

        resizeList();
        drawCenterMarker();

        let geolet = L.geolet({
            position: 'topleft',
            geoOptions: { enableHighAccuracy: true, maximumAge: 30000, timeout: 15000 },
            marker: false,
            popup: false,
        }).addTo(map);
        map.on('geolet_success', function (data) {
            mc.detectingLocation = true;
            moveToCurrent({latitude: data.raw.coords.latitude, longitude: data.raw.coords.longitude});
            geolet.deactivate();
        });
        map.on('geolet_error', ({ error }) => {
            mc.detectingLocation = false;
            mc.didNotDetectLocation = true;
            mc.ScrollToTopOfList();
            resizeList();
        });
        map.doubleClickZoom.disable();
        map.on('dblclick', function (e) {
            mc.doubleClickedMap = true;
            mc.detectingLocation = true;
            moveToCurrent({latitude: e.latlng.lat, longitude: e.latlng.lng});
        });

    });

    //this function resets the filter choices
    function filterReset() {
        mc.hideAllExpanded();
        mc.language = "Language";
        mc.riteFilter = "Rite";
        mc.timesString = "Anytime";
        mc.timesButtonText = "Times";
        displayRs();
    }

    function openInfoWindow(selectedParishId) {

        markers[selectedParishId].openPopup();
        leafletMap.panTo(markers[selectedParishId].getLatLng());
        // see https://leafletjs.com/reference.html panInside()
        // set the bounds of the leaflet map leafletMap to the marker[id] and centerMarker
        // let bounds = L.latLngBounds(markers[id].getLatLng(), centerMarker.getLatLng());
        // leafletMap.fitBounds(bounds);
        // leafletMap.panInsideBounds(bounds)
        // leafletMap.panInside(markers[selectedParishId].getLatLng(), {padding: [50, 50]})

        highlightParish(selectedParishId);
    }

    function findMyLocation() {
        mc.detectingLocation = true;
        geoLocationFactory.getCurrentPosition().then(
                function (position)
                {
                    moveToCurrent(position);
                })
            .catch(function (err) {
                mc.detectingLocation = false;
                mc.didNotDetectLocation = true;
                resizeList();
            });
    }


    function scrollToTop(oElement, sContainer, fnCallback) {
        var oContainer, nElemTop, offsetpadding;
        offsetpadding = 10;

        if (!oElement || oElement.length <= 0) {
            return;
        }

        oContainer = (typeof sContainer === "object" ? sContainer : $(sContainer));
        nElemTop = oElement[0].offsetTop - offsetpadding;

        oContainer.animate({ scrollTop: nElemTop }, { duration: "fast", complete: fnCallback });

    }

    function scrollIntoView(oElement, sContainer, fnCallback)
    {
        var oContainer, nContainerTop, nContainerBottom, nElemHeight, nElemTop, nElemBottom, offsetpadding;

        if (!oElement || oElement.length <= 0) {
            return;
        }

        if (window.innerWidth < widthIsFiltersCollapsed) {
            offsetpadding = 42;
            if (window.innerWidth < widthIsNarrow) offsetpadding = 8;
        } else offsetpadding = 62;

        oContainer = (typeof sContainer === "object" ? sContainer : $(sContainer));
        nContainerTop = oContainer.scrollTop();
        nContainerBottom = nContainerTop + oContainer.height();
        nElemHeight = oElement.height() || 25;
        nElemTop = oElement[0].offsetTop - offsetpadding;
        nElemBottom = nElemTop + nElemHeight + 100;
        if ((nElemTop < nContainerTop) || (nElemHeight >= $(sContainer).height())) {
            oContainer.animate({ scrollTop: nElemTop }, { duration: "fast", complete: fnCallback });
        } else if (nElemBottom > nContainerBottom) {
            oContainer.animate({ scrollTop: (nElemBottom - $(sContainer).height()) }, { duration: "fast", complete: fnCallback });
        } else if (fnCallback) {
            fnCallback();
        }
    }

    function scrollParishToTop(selectedParishId)
    {
        var elemId = '#' + selectedParishId + '-serviceTimes';
        var parentTimePanel = $(elemId).parent().parent().parent().parent().parent().parent().parent();
        scrollToTop(parentTimePanel, $("#list-scrollable"));

        elemId = '#' + selectedParishId;
        parentTimePanel = $(elemId).parent().parent().parent();
        scrollToTop(parentTimePanel, $("#list-scrollable"));

        //was attempt to hide any expanded parish detail panels and then scroll into view
        //$(".parish-detail-panel").hide("fast", scrollToTop(parentTimePanel, $("#list")));
        //mc.detailsShowingParishId.id = 0;
    }

    function highlightParish(selectedParishId)
    {
        mc.highlightedParish = selectedParishId;
    }

    //move map to current location
    function moveToCurrent(position, refresh = true)
    {

        // immediately truncate to 3 decimal places which is within 111 meters accurate
        if(typeof position.latitude === "number"){
            position.latitude = position.latitude ? position.latitude = parseFloat(position.latitude.toFixed(3)) : position.latitude;
            position.longitude = position.longitude ? position.longitude = parseFloat(position.longitude.toFixed(3)) : position.longitude;
        } else if(typeof position.latitude === "string" && typeof position.longitude === "string"){
            position.latitude = position.latitude.substr(0, position.latitude.indexOf(".")+4);
            position.longitude = position.longitude.substr(0, position.longitude.indexOf(".")+4);
        }

        mc.latitude = position.latitude;
        mc.longitude = position.longitude;
        mc.searchResult = position.locationName !== undefined ? position.locationName: mc.searchResult;
        if(refresh) updateURL(position);

        mc.didNotDetectLocation = false;
        mc.detectingLocation = false;
        mc.doubleClickedMap = false;

        centerChangedCallback(refresh);
    }

    function updateURL(position) {
        if(!mc.location.search().lat)
            mc.location.replace(); // we're at /map or /map?SearchQueryTerm=str so history gets replaced with:
        mc.location.search("lat", position.latitude);
        mc.location.search("lng", position.longitude);
        if (mc.searchResult && !mc.detectingLocation && !mc.doubleClickedMap) {
            mc.location.search("SearchQueryTerm", mc.searchResult);
            window.document.title = "MassTimes - " + mc.searchResult;
        } else if (!mc.parishService.locationName || mc.detectingLocation || mc.doubleClickedMap){
            mc.location.search("SearchQueryTerm", null);
            window.document.title = "MassTimes - Map";
            mc.parishService.locationName = null;
            mc.searchResult = null;
            setSearchFormText();
        }
    }

    $window.addEventListener('popstate', function(event) {
        if(mc.location.path() !== '/map') return;
        // this is on browser back/forward, get values off the url:
        var params = mc.location.search();
        mc.latitude = params.lat;
        mc.longitude = params.lng;
        mc.searchResult = params.SearchQueryTerm;
        moveToCurrent({latitude: mc.latitude, longitude: mc.longitude, locationName: mc.searchResult});
        if(mc.location.search()["sortDistance"]) mc.ChangeDayOfWeek(mc.Daylist[7], true);
        else mc.ChangeDayOfWeek(null, true);
        setSearchFormText();
    });

    //apply the filter choices to the unfiltered data and reload the parish colection to which everything is bound
    function applyFilter(scrollToTop = true, doNotChangeRs = false) {

        if (!unfilteredparishes) return;

        if(!doNotChangeRs) resetDisplayR();

        var list = [],
            listUnused = [];

        mc.filteredServTimes = [];

        if(mc.ServiceType.searchName == 'All'){
            if(mc.language === 'Language' && mc.riteFilter === 'Rite' && mc.timesButtonText === 'Times'){
                mc.parishes = unfilteredparishes;
                mc.parishesUnused = [];
            }
            else {
                mc.filteredServTimes = Enumerable.From(mc.servTimes)
                  // .Where(function (x) {
                  //     return (mc.riteFilter === 'Rite' || x.rite_type_name.trim() === mc.riteFilter);
                  // })
                  .Where(function (x) {
                      return (mc.language === 'Language' || x.language.trim() === mc.language);
                  })
                  .Where(function (x) {
                      return (x.startTimeInMinutes >= mc.filterStartTimeInMinutes && x.startTimeInMinutes <= mc.filterEndTimeInMinutes);
                  })
                  .ToArray();

                for (var p in unfilteredparishes) {
                    var foundParishInList = mc.filteredServTimes.filter(function (e) {
                        return e.id === unfilteredparishes[p].id;
                    });
                    var riteFilterSuccess = false;
                    if(mc.riteFilter === 'Rite' ||
                        (unfilteredparishes[p].rite_type_name &&
                            unfilteredparishes[p].rite_type_name.length > 0 &&
                            typeof unfilteredparishes[p].rite_type_name.trim === 'function' &&
                            (unfilteredparishes[p].rite_type_name.trim() === mc.riteFilter)
                        )
                    ){
                        riteFilterSuccess = true;
                    }
                    if (foundParishInList.length > 0 && riteFilterSuccess) {
                        list.push(unfilteredparishes[p]);
                    } else {
                        listUnused.push(unfilteredparishes[p]);
                    }
                }

                mc.parishes = list;
                mc.parishesUnused = listUnused;
            }
        }

        if (mc.ServiceType.searchName !== 'All') {
            if (mc.ServiceType.searchName === 'Adorations') {
                mc.filteredServTimes = Enumerable.From(mc.servTimes)
                  .Where(function (x) { return (x.currentDay.trim() === mc.DayOfWeek.name || x.currentDay.trim() === mc.DayOfWeek.alias || x.isPerpetual); })
                  .Where(function (x) { return x.serviceType === mc.ServiceType.searchName; })
                  .Where(function (x) { return (mc.riteFilter === 'Rite' || x.rite_type_name === mc.riteFilter); })
                  .Where(function (x) { return (mc.language === 'Language' || x.language === mc.language); })
                  .Where(function (x) { return x.startTimeInMinutes >= mc.filterStartTimeInMinutes && x.startTimeInMinutes <= mc.filterEndTimeInMinutes; })
                  .ToArray();
            } else {
                mc.filteredServTimes = Enumerable.From(mc.servTimes)
                  .Where(function (x) { return (x.currentDay.trim() === mc.DayOfWeek.name || x.currentDay.trim() === mc.DayOfWeek.alias); })
                  .Where(function (x) { return x.serviceType === mc.ServiceType.searchName; })
                  .Where(function (x) { return (mc.riteFilter === 'Rite' || (x.rite_type_name && x.rite_type_name.trim() === mc.riteFilter)); })
                  .Where(function (x) {
                      return (mc.language === 'Language' || x.language.trim() === mc.language);
                  })
                  .Where(function (x) {
                      return (x.startTimeInMinutes >= mc.filterStartTimeInMinutes && x.startTimeInMinutes <= mc.filterEndTimeInMinutes);
                  })
                  .ToArray();
            }
            mc.filteredTimes = array_unique(mc.filteredServTimes);
            listUnused = [];
            for (var p in unfilteredparishes) {
                if (mc.filteredServTimes.filter(function(e) { return e.id === unfilteredparishes[p].id; }).length === 0) {
                    listUnused.push(unfilteredparishes[p]);
                }
                else {
                    list.push(unfilteredparishes[p]);
                }
            }
            mc.parishesUnused = listUnused;
            mc.parishes = list;
        }

        if(!doNotChangeRs) displayRs();
        setCount();
        displayMarkers();
        if(scrollToTop) mc.ScrollToTopOfList();
    }

    function centerChangedCallback(refresh = true) {

        if(refresh) drawCenterMarker();

        if(mc.latitude == null || mc.longitude == null) {
            mc.couldNotReceiveParishes = true;
            return;
        }

        mc.languages = [];
        mc.riteList = [];
        mc.parishPage = 1;
        mc.parishService.getParishes(mc.latitude, mc.longitude, mc.parishPage)
            .then(function (data) {

                mc.couldNotReceiveParishes = false;
                rawParishList = angular.copy(data.data);
                mc.collapseAllText = "Collapse"
                mc.loadExtraParishesText = "Load 30 more Parishes";
                unfilteredparishes = cleanUnfilteredArray(data.data);

                mc.parishes = unfilteredparishes;

                setCount();

                applyFilter();

                if(refresh && !mc.searchResult && data && data.data && data.data.length) {
                    var title = data.data[0].church_address_city_name + ", " + data.data[0].church_address_providence_name;
                    window.document.title = "MassTimes - " + title
                    mc.location.search("SearchQueryTerm", title).replace();
                }

                if(refresh) resizeList();
                doWorker();
            })
            .catch(function (err) {
                mc.couldNotReceiveParishes = true;
                mc.ScrollToTopOfList();
                resizeList();
            });
    }
    const worker = new CSVWorkerWrapper();
    const csvUrl = '../../bulletins-urls.csv';
    function doWorker() {
        const idsToMatch = unfilteredparishes.map(parish => parish.id);
        worker.fetchCSV(csvUrl, idsToMatch)
        .then(data => {
            $scope.$applyAsync(function(){
                for (const element of data) {
                    const parishId = element.id;
                    const parishData = element;
                    mc.parishesByParishId[parishId].bUrl = parishData.url;
                }
            });
        })
        .catch(error => {});
    }


    function array_unique(arr) {
        var result = [],
            found = false;
        for (var i = 0; i < arr.length; i++) {
            found = false;
            for (var j = 0; j < result.length; j++) {
                if (result[j].currentTime === arr[i].currentTimes) {
                    found = true;
                    break;
                }
            }
            if (found === false) {
                var obj = {};

                obj.currentTime = arr[i].currentTimes;
                obj.val = obj.currentTime == "Perpetual" ? "" : Date.parse('01/01/2000 23:59') - Date.parse('01/01/2000 ' + arr[i].currentTimes);

                result.push(obj);
            }
        }
        return result;
    }

    //stores the count of the filtered data
    function setCount() {
        var element_count = 0;
        for (var e in mc.parishes)
            if (mc.parishes.hasOwnProperty(e))
                element_count++;
        mc.elementCount = element_count;
    }

    let centerMarker = null;
    function drawCenterMarker(){

        if(!leafletMap || !mc.latitude) return;

        leafletMap.setView([mc.latitude, mc.longitude], 11);
        // leafletMap.panTo([mc.latitude, mc.longitude]);
        leafletMap.invalidateSize();

        if(!centerMarker)
            centerMarker = L.circleMarker([mc.latitude, mc.longitude], {
                radius: 8,
                color: '#3A87AD',
                weight: 4,
                opacity: 1,
                fillOpacity: 0,
            }).addTo(leafletMap);
        else centerMarker.setLatLng([mc.latitude, mc.longitude]);
    }
    function createSvgMarker(number, color = '#FF0000') {
        return L.divIcon({
            className: "leaflet-data-marker",
            html: `<svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" width="26" height="36" viewBox="10 12 38 34"><path d="M28.767 2.76c-8.97 0 -16.239 7.135 -16.239 15.932C12.528 27.492 28.767 56.35 28.767 56.35s16.24 -28.858 16.24 -37.658c0 -8.797 -7.274 -15.932 -16.24 -15.932" stroke="none" fill="#FE7569" />
                    <text x="29" y="31" text-anchor="middle" fill="black" font-size="22px" font-weight="500">${number}</text></svg>`,
                iconSize    : [10, 10],
                iconAnchor  : [10, 26],
                popupAnchor : [3, -26]
        })
    }
    function createMarker(info) {
        let marker = L.marker([info.latitude, info.longitude], {
            icon: createSvgMarker(info.resultID)
        }).bindPopup(info.name).addTo(leafletMap);
        marker.on('click', function(e) { showHideParishDetails(info.id, true); });
        return marker;
    }
    function displayMarkers() {
        mc.highlightedParish = 0;
        mc.detailsShowingParishId.id = 0;

        if(markers && markers.length) {
            for (const id in markers) {
                leafletMap.removeLayer(markers[id]);
            }
        }

        mc.markers = [];
        mc.markersByParishId = [];
        mc.infoWindows = [];
        mc.graphicsByParishId = [];

        //to limit according to whether it was searched or not, use this later:
        //var distanceLimit = 10;
        //if (!mc.parishService.detectLocation) {
        //    distanceLimit = 5;
        //}

        var pinLimit = (window.innerWidth < widthIsNarrow) ? 1 : 4;

        if (mc.elementCount > 0) {

            var boundsGeometrys = [];
            if(centerMarker){
                boundsGeometrys.push(centerMarker.getLatLng());
            }
            markers = [];
            for (var i = 0; i < mc.elementCount; i++) {
                markers[mc.parishes[i].id] = createMarker(mc.parishes[i]);

                // bounds: show 1 pin if small screen, or four pins, limited to 15 mi or always at least show 1 pin.
                if (i < pinLimit && (mc.parishes[i].distance < 15 || i === 0)) {
                    let graphic = markers[mc.parishes[i].id];
                    if(graphic) {
                        boundsGeometrys.push(graphic.getLatLng());
                    }
                }
            }

            if(leafletMap && (mc.parishPage == 1) && centerMarker){
                // leafletMap.fitBounds(boundsGeometrys, { padding: [40, 40] });
            }

        } else if(centerMarker) {
            leafletMap.panTo(centerMarker.getLatLng());
        }

    }

    function addRiteToList(rite){
        if (rite && rite.length > 1) {
            if (rite === "Roman-Latin") rite = "Roman";
            if (!mc.riteList.includes(rite)) mc.riteList.push(rite);
        }
        return rite;
    }
    function addLanguageToList(language){
        if (language && language.length > 1 && !mc.languages.includes(language)) mc.languages.push(language);
    }
    function formatDistance(miles) {
        if (mc.userSettings.distanceDisplayKm)
            return Number(miles * 1.60934).toFixed(2).toString() + " km";
        else return Number(miles).toFixed(2).toString() + " miles";
    }
    //this cleans the bad data in the parish data
    function cleanUnfilteredArray(unfilteredArray) {
        var list1 = [];

        mc.servTimes = [];
        mc.parishesByParishId = [];

        for (var e in unfilteredArray) {
            if ((unfilteredArray[e].url !== undefined) && (unfilteredArray[e].url !== null) && (unfilteredArray[e].url.length > 0)) {
                //clean up some of the URL dirtiness
                unfilteredArray[e].url = $.trim(unfilteredArray[e].url);
                if (unfilteredArray[e].url.substring(0, 7) !== 'http://' || unfilteredArray[e].url.substring(0, 8) !== 'https://'){
                    if (unfilteredArray[e].url.substring(0, 6) === 'http//') {
                        unfilteredArray[e].url = 'http://' + unfilteredArray[e].url.substr(7, unfilteredArray[e].url.length);
                    } else if (unfilteredArray[e].url.substring(0, 7) === 'https//') {
                        unfilteredArray[e].url = 'https://' + unfilteredArray[e].url.substr(8, unfilteredArray[e].url.length);
                    } else if (unfilteredArray[e].url.substring(0, 7) !== 'http://' && unfilteredArray[e].url.substring(0, 8) !== 'https://') {
                            unfilteredArray[e].url = 'http://' + unfilteredArray[e].url;
                    }
                }
            }
            unfilteredArray[e].language_name = $.trim(unfilteredArray[e].language_name);
            addLanguageToList(unfilteredArray[e].language_name);
            unfilteredArray[e].rite_type_name = addRiteToList($.trim(unfilteredArray[e].rite_type_name));
            unfilteredArray[e].formatted_rite_language = unfilteredArray[e].rite_type_name + ' rite, in ' + unfilteredArray[e].language_name;

            unfilteredArray[e].distance = Number(unfilteredArray[e].distance).toFixed(2).toString();
            unfilteredArray[e].distanceDisplay = formatDistance(unfilteredArray[e].distance);
            unfilteredArray[e].googleDirections = [];
            unfilteredArray[e].Services = [];

            if (unfilteredArray[e].church_worship_times && unfilteredArray[e].church_worship_times.length > 0) {
                //ok we have items in the child array.  We need to loop through those and clean the times, and concatenate by day.
                //mass times first

                list1 = Enumerable.From(unfilteredArray[e].church_worship_times)
                .Where(function (x) { return (x.service_typename === 'Week Days' || x.service_typename === 'Weekend'); })
                .ToArray();
                if (list1 !== undefined) {
                    const filterList = angular.copy(list1);
                    list1.filter(obj =>
                        $.trim(obj.day_of_week) === 'Saturday' &&
                        obj.service_typename === 'Weekend'
                        // && formatTimeInMinutes(obj.time_start) >= 721 // 721 is 12:01pm in minutes (960 would be 4pm)
                    ).forEach(obj => { obj.vigil = true; obj.day_of_week = 'Sunday'});
                    list1.sort(customSort);
                    unfilteredArray[e].Services = unfilteredArray[e].Services.concat(processArray(list1, 'Mass', unfilteredArray[e]));
                    mc.servTimes = mc.servTimes.concat(processFilterArray(filterList, 'Mass', unfilteredArray[e]));
                }

                list1 = Enumerable.From(unfilteredArray[e].church_worship_times)
                .Where(function (x) { return (x.service_typename === 'Confessions'); })
                .ToArray();
                if (list1 !== undefined) {
                    unfilteredArray[e].Services = unfilteredArray[e].Services.concat(processArray(list1, 'Confessions', unfilteredArray[e]));
                    mc.servTimes = mc.servTimes.concat(processFilterArray(list1, 'Confessions', unfilteredArray[e]));
                }

                const holyDayServices = unfilteredArray[e].church_worship_times
                    .filter(({ service_typename }) => ['Holy Days', 'Vigil for Holy Days'].includes(service_typename))
                    // force all to 'Holy Days' and one single day 'Monday' so they display on one line:
                    .map(obj => ({ ...obj,
                        vigil: obj.service_typename === 'Vigil for Holy Days',
                        service_typename: 'Holy Days',
                        day_of_week: 'Monday'
                    }))
                    .sort(customSort);
                unfilteredArray[e].Services = unfilteredArray[e].Services.concat(processArray(holyDayServices, 'Holy Days', unfilteredArray[e]));
                mc.servTimes = mc.servTimes.concat(processFilterArray(
                    // this filters down to distinct time_start so filters will work correctly:
                    holyDayServices.filter((obj, idx, arr) => arr.findIndex((o) => o.time_start === obj.time_start) === idx),
                    'Holy Days',
                    unfilteredArray[e]
                ));

                list1 = Enumerable.From(unfilteredArray[e].church_worship_times)
                .Where(function (x) { return (x.service_typename === 'Devotions'); })
                .ToArray();
                if (list1 !== undefined) {
                    unfilteredArray[e].Services = unfilteredArray[e].Services.concat(processArray(list1, 'Devotions', unfilteredArray[e]));
                    mc.servTimes = mc.servTimes.concat(processFilterArray(list1, 'Devotions', unfilteredArray[e]));
                }

                list1 = Enumerable.From(unfilteredArray[e].church_worship_times)
                .Where(function (x) { return (x.service_typename === 'Adorations'); })
                .ToArray();
                if (list1 !== undefined) {
                    unfilteredArray[e].Services = unfilteredArray[e].Services.concat(processArray(list1, 'Adorations', unfilteredArray[e]));
                    mc.servTimes = mc.servTimes.concat(processFilterArray(list1, 'Adorations', unfilteredArray[e]));
                }

            } else {
                if(unfilteredArray[e].language_name) {// parish has no service time, so add dummy service time with language
                    var list1 = [{
                        comment: "",
                        day_of_week: "",
                        id: "1",
                        is_perpetual: false,
                        language: unfilteredArray[e].language_name,
                        service_typename: "",
                        time_end: "24:59:00",
                        time_start: "24:59:00",
                    }];
                    unfilteredArray[e].Services = unfilteredArray[e].Services.concat(processArray(list1, 'dummy', unfilteredArray[e]));
                    mc.servTimes = mc.servTimes.concat(processFilterArray(list1, 'dummy', unfilteredArray[e]));
                }
            }
            mc.parishesByParishId[unfilteredArray[e].id] = unfilteredArray[e];
        }
        return unfilteredArray;

    }

    //creates cleaner datasets of the worship times and attaches them to the unfiltered data
    function processArray(list1, serviceType, parish) {
        const list2 = [];
        let currentDay = "";
        let currentTimes = "";

        function createEntry(item, day, times) {
            return {
                currentDay: day,
                currentTimes: times,
                serviceType: serviceType,
                isPerpetual: item.is_perpetual || !item.day_of_week,
                comment: formatComment(item.comment, item.language, parish.language_name),
                // endTime: item.time_end ? formatStartTimeEndTime(item.time_end) : "",
                // startTimeInMinutes: formatTimeInMinutes(item.time_start),
                // endTimeInMinutes: formatTimeInMinutes(item.time_end),
                // rite_type_name: parish.rite_type_name,
                // language: item.language === null ? parish.language_name : item.language,
            };
        }

        function formatComment(item, defaultLanguage) {
            let formattedComment = "";
            if (defaultLanguage && item.language && ($.trim(item.language) !== $.trim(defaultLanguage))) {
                formattedComment += $.trim(item.language);
            }
            formattedComment += (formattedComment && $.trim(item.comment) ? ', ' : '') + $.trim(item.comment);
            return (item.vigil ? ' vigil' : '') + (formattedComment.length > 0 ? ` (${formattedComment})` : '');
        }
        for (let f in list1) {
            const item = list1[f];
            const formattedDay = $.trim(item.day_of_week);

            if (!item.day_of_week) {
                list2.push(createEntry(item, "", formatComment(item, parish.language_name)));
            } else if (currentDay === formattedDay) {
                currentTimes += `, ${formatStartTimeEndTime(item.time_start, item.time_end)}${formatComment(item, parish.language_name)}`;
            } else {
                if (currentTimes.length > 0) {
                    list2.push(createEntry({ ...item, day_of_week: currentDay }, currentDay, currentTimes));
                }
                currentDay = formattedDay;
                currentTimes = `${formatStartTimeEndTime(item.time_start, item.time_end)}${formatComment(item, parish.language_name)}`;
            }
        }

        if (currentTimes !== "") {
            list2.push(createEntry({ ...list1[list1.length - 1], day_of_week: currentDay }, currentDay, currentTimes));
        }

        return list2;
    }

    //creates cleaner datasets of the worship times and attaches them to the unfiltered data
    function processFilterArray(list1, serviceType, parish) {
        var list2 = [];
        var currentDay = "";
        var currentTimes = "";
        var entry = {};
        for (var f in list1) {
            entry = {};
            entry.serviceType = serviceType;
            entry.vigil = $.trim(list1[f].day_of_week) === 'Saturday' && list1[f].service_typename === 'Weekend'
            entry.id = parish.id;
            entry.resultID = parish.resultID;
            entry.name = parish.name;
            entry.church_address_city_name = parish.church_address_city_name;
            entry.church_address_providence_name = parish.church_address_providence_name;
            entry.rite_type_name = parish.rite_type_name;
            entry.language = list1[f].language === null ? parish.language_name : list1[f].language;
            entry.distance = Number(parish.distance).toFixed(2).toString();
            entry.distanceDisplay = formatDistance(parish.distance);
            entry.isPerpetual = list1[f].is_perpetual;

            formattedComment = $.trim(list1[f].comment);
            if (parish.language_name && list1[f].language && ($.trim(list1[f].language) != $.trim(parish.language_name))){
                formattedComment = $.trim(list1[f].language) + (formattedComment ? ", " + formattedComment : "");
                addLanguageToList($.trim(list1[f].language));
            }
            entry.comment = formattedComment ? '(' + formattedComment + ')' : null;

            if (list1[f].day_of_week === undefined) {
                entry.currentDay = "";
                entry.currentTimes = $.trim(list1[f].comment);
                entry.endTime = list1[f].time_end ? formatStartTimeEndTime(list1[f].time_end) : "";
                entry.startTimeInMinutes = formatTimeInMinutes(list1[f].time_start);
                entry.endTimeInMinutes = formatTimeInMinutes(list1[f].time_end);
                list2.push(entry);
                currentDay = "";
                currentTimes = "";

            }
            else {
                //now we create the new variables and start storing
                currentDay = $.trim(list1[f].day_of_week);
                currentTimes = formatStartTimeEndTime(list1[f].time_start);
                if(list1[f].is_perpetual) {
                    currentTimes = "Perpetual";
                    currentDay = "All";
                }

                if (currentTimes.length > 0) {
                    //store whatever we had

                    entry.currentDay = currentDay;
                    entry.currentTimes = currentTimes;
                    entry.endTime = list1[f].time_end ? formatStartTimeEndTime(list1[f].time_end) : "";
                    entry.startTimeInMinutes = formatTimeInMinutes(list1[f].time_start);
                    entry.endTimeInMinutes = formatTimeInMinutes(list1[f].time_end);
                    list2.push(entry);
                    currentDay = "";
                    currentTimes = "";
                }
            }
        }
        if (currentTimes !== "") {
            var lastentry = {};

            lastentry.currentDay = currentDay;
            lastentry.currentTimes = currentTimes;
            lastentry.endTime = list1[f].time_end ? formatStartTimeEndTime(list1[f].time_end) : "";
            lastentry.startTimeInMinutes = formatTimeInMinutes(list1[f].time_start);
            lastentry.endTimeInMinutes = formatTimeInMinutes(list1[f].time_end);
            lastentry.serviceType = serviceType;
            lastentry.vigil = $.trim(list1[f].day_of_week) === 'Saturday' && list1[f].service_typename === 'Weekend'
            lastentry.id = parish.id;
            lastentry.resultID = parish.resultID;
            lastentry.name = parish.name;
            lastentry.rite_type_name = parish.rite_type_name;
            lastentry.language = list1[f].language === null ? parish.language_name : list1[f].language;
            lastentry.church_address_city_name = parish.church_address_city_name;
            lastentry.church_address_providence_name = parish.church_address_providence_name;
            lastentry.distance = parish.distance;
            lastentry.distanceDisplay = formatDistance(parish.distance);

            list2.push(lastentry);
        }
        return list2;
    }

    function formatStartTimeEndTime(starttime, endtime){
        var formattedTime;
        formattedTime = new Date(toDate(starttime, "h:m")).getFormattedTime(mc.userSettings.timeDisplay24);
        if(endtime) {
            formattedTime += " to " + new Date(toDate(endtime, "h:m")).getFormattedTime(mc.userSettings.timeDisplay24);
        }
        return formattedTime;
    }

    function formatTimeInMinutes(timeString){
        if(timeString === null || timeString === undefined){
            return 1440;
        }
        var date = new Date(toDate(timeString, "h:m"));
        return date.getHours() * 60 + date.getMinutes();
    }

    function customSort(a, b) {
        const dayA = a.day_of_week.trim();
        const dayB = b.day_of_week.trim();

        const indexA = dayMapping[dayA] || Infinity;
        const indexB = dayMapping[dayB] || Infinity;

        // Compare days first
        if (indexA !== indexB) {
            return indexA - indexB;
        }

        // If days are the same, prioritize vigil services
        if (a.vigil && !b.vigil) {
            return -1;
        } else if (!a.vigil && b.vigil) {
            return 1;
        }

        // If neither or both are vigils, keep original order
        return 0;
    }
}]);
})();
