// from https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
window.sentryOnLoad = function () {
  Sentry.init({
    release: '2.1.2',
    beforeSend(event, hint) {
      if(hint?.originalException?.name === 'webgl:required') return null;
      if(hint?.originalException?.name === 'geolocation:timeout') return null;
      if(hint?.originalException?.name === 'request:server' && hint?.originalException?.details?.url === 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer') return null;
      try {
        if (event.exception.values[0].value == 'Object captured as promise rejection with keys: details, message, name' && hint?.originalException.message) {
          event.exception.values[0].type = hint.originalException.name;
          event.exception.values[0].value = hint.originalException.message;
        }
      } catch (e) {
        return event;
      }
      return event;
    },
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      'Can\'t find variable: ZiteReader',
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      'Script error.',
      '__gCrWeb.autofill.extractForms',
      "Can't find variable: inf", // Chrome mobile on iOS
      'hilitor',
      '[object Event]', // unresolved https://github.com/getsentry/raven-js/issues/360
      // Firefox extensions
      '/^resource:\/\//i',
      // Ignore Google flakiness
      '/\/(gtm|ga|analytics)\.js/i',
      'Cannot set property \'destroySlots\' of undefined',
      // these four were added 2/19/2024 when one weekend saw 3K flood of err from gtag/js as TypeError undefined:
      'gtag',
      'reading \'_AW',
      'getRestrictions',
      'b.container',
      '[object GeolocationPositionError]',
      'Can\'t find variable: gbViewDidDisappear',
      'Can\'t find variable: gbViewWillDisappear',
      'Can\'t find variable: gbViewWillAppear',
      'Can\'t find variable: gbUserInfo',
      'Can\'t find variable: gbViewDidAppear',
      'Can\'t find variable: gbPluginDidLoad',
      'Can\'t find variable: gb',
      'Non-Error exception captured',
      'Invalid call to runtime.sendMessage(). Tab not found.',
      // 'Non-Error promise rejection captured',
      // 'Object captured as promise rejection',
      // 'Object captured as promise rejection with keys: details, message, name',
    ],
    denyUrls: [
      // Google Adsense
      /pagead\/js\/adsbygoogle\.js/i,
      /www\.googletagmanager\.com/i,
      // Facebook
      /graph\.facebook\.com/i,
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      /securepubads\.g\.doubleclick\.net/i
    ]
  });
  Sentry.setTag("isApp", isApp);
};
