function CSVWorkerWrapper() {
    let worker = new Worker('./assets/scripts/csvWorker.js');
    let promises = {};

    this.fetchCSV = function(url, ids) {
      return new Promise(function(resolve, reject) {
        let promiseId = Date.now();
        promises[promiseId] = { resolve: resolve, reject: reject };

        worker.postMessage({ url: url, ids: ids, promiseId: promiseId });

        worker.onmessage = function(event) {
          if (event.data.type === 'success') {
            let receivedPromiseId = event.data.promiseId;
            if (promises[receivedPromiseId]) {
              promises[receivedPromiseId].resolve(event.data.data);
              delete promises[receivedPromiseId];
            }
          } else if (event.data.type === 'error') {
            let receivedPromiseId = event.data.promiseId;
            if (promises[receivedPromiseId]) {
              promises[receivedPromiseId].reject(new Error(event.data.message));
              delete promises[receivedPromiseId];
            }
          }
        };
      });
    };

    this.terminate = function() {
      worker.terminate();
    };
  }